import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import NotificationService from '../services/notification'
import { Modal, Pagination } from '.'
import { NotificationsLoadingTypes } from '../redux/reducers/notification/types'
import { useActions } from '../hooks/useActions'
import { ReactComponent as EditIcon } from '../assets/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg'
import { ReactComponent as BellIcon } from '../assets/bell-icon.svg'
import { ReactComponent as AddIcon } from '../assets/add-icon.svg'
import { ReactComponent as ScheduleIcon } from '../assets/schedule-icon.svg'
import { NOTIFICATION_PUSH_STATUS } from '../utils/consts'
import moment from 'moment';
const Notifications = ({ notFilter, passSelectedPass }) => {
  const {
    notification: { notifications, count: totalCount },
    pass: { passes },
  } = useSelector(({ notification, pass }) => {
    return { notification, pass }
  })

  const [selectedPass, setSelectedPass] = useState(
    passSelectedPass ? passSelectedPass : '',
  )

  const [notificationsData, setNotificationsData] = useState({
    title: '',
    body: '',
    url: '',
    id: -1,
  })

  const [modalActive, setModalActive] = useState(false)

  const [isPushNotifications, setIsPushNotifications] = useState([])

  const [deleteCandidateNotification, setDeleteCandidateNotification] =
    useState({})
  const [deleteScheduleNotification, setDeleteScheduleNotification] = useState({})

  const [
    confirmDeleteNotificationModalActive,
    setConfirmDeleteNotificationModalActive,
  ] = useState(false)

  const [openScheduledModal, setOpenScheduledModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const {
    createNotification,
    updateNotification,
    deleteNotification,
    cancelSchedule,
    getNotificationListByPassId,
    getNotificationsCount,
    notificationsDragAndDrop,
    getPassListByCompanyId,
    loadingStart,
    loadingEnd,
  } = useActions()

  const getNotifications = useCallback(
    async page => {
      if (!page) page = 0

      await getNotificationListByPassId({
        passId: selectedPass,
        limit: 10,
        offset: page * 10,
      })

      await getNotificationsCount({
        passId: selectedPass,
      })
    },
    [selectedPass, getNotificationListByPassId, getNotificationsCount],
  )

  const findAndFormatNotification = () => {
    const notification = notifications.find((n) => n.body);
    if (notification) {
      const date = moment(notification.updated);
      setSelectedDate(date.format('DD.MM.YYYY'));
      setSelectedTime(date.format('HH:mm'));
    }
  };

  const isDisabledBtn = itemId => {
    return isPushNotifications.includes(itemId)
  }

  const formSubmitHandle = e => {
    e.preventDefault()
    if (renderNotificationModalForm()) {
      createNotification({
        passId: selectedPass,
        ...notificationsData,
      })
      setModalActive(false)
      return
    }

    updateNotification({
      passId: selectedPass,
      ...notificationsData,
    })
    setModalActive(false)
  }

  const renderNotificationModalForm = () => {
    if (notificationsData.id === -1) return true
    return false
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = [...notifications]

    const sequence = items[result.destination.index].sequence

    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    notificationsDragAndDrop(items, {
      sequence,
      objectId: +result.draggableId,
    })
  }

  const createBtn = () => {
    setNotificationsData({
      title: '',
      body: '',
      url: '',
      id: -1,
    })
    setModalActive(true)
  }

  const reload = page => getNotifications(page)

  useEffect(() => {
    setSelectedPass(passSelectedPass)
  }, [passSelectedPass])

  useEffect(() => {
    if (notFilter) return
    ;(async () => {
      await getPassListByCompanyId()
    })()
  }, [getPassListByCompanyId, notFilter])

  useEffect(() => {
    if (!selectedPass) return
    ;(async () => {
      await getNotifications()
    })()
  }, [getNotifications, selectedPass])

  useEffect(() => {
    notifications.forEach(item => {
      if (item.status > 0) {
        setIsPushNotifications(oldState => [...oldState, item.id])
      }
    })
  }, [notifications])

  useEffect(() => {
    checkIfSaveShouldBeEnabled(selectedDate, selectedTime);
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    findAndFormatNotification();
  }, []);

  const { title, body, url } = notificationsData

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    checkIfSaveShouldBeEnabled(newDate, selectedTime);
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setSelectedTime(newTime);
    checkIfSaveShouldBeEnabled(selectedDate, newTime);
  };

  const checkIfSaveShouldBeEnabled = (date, time) => {
    if (date && time) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const handleScheduledSave = async () => {
    if (selectedDate && selectedTime) {

      try {
        await NotificationService.sendScheduled(notificationsData.id, selectedDate, selectedTime);
        console.log("Notification scheduled successfully");
      } catch (error) {
        console.error("Failed to schedule notification", error);
      }

      setIsPushNotifications(oldState => [
        ...oldState,
        notificationsData.id,
      ]);

      setOpenScheduledModal(false);
    } else {
      console.log("Дата или время не выбраны.");
    }
  };

  const scheduleTimeZOne = true;

  return (
    <>
      {!notFilter && (
        <select
          className='notifications-pass-filter'
          value={selectedPass}
          onChange={e => {
            setSelectedPass(+e.target.value)
          }}
        >
          <option value={''}>Not selected</option>
          {passes.map(({ id, title }) => {
            return (
              <option key={id} value={id}>
                {title}
              </option>
            )
          })}
        </select>
      )}

      {selectedPass ? (
        <div className='notifications'>
          <header className='notifications_header table-list-header'>
            <div className='table-list-header_title'>Messages</div>
            <span
              className='table-list-header_add-btn add-btn btn'
              onClick={() => {
                createBtn()
              }}
            >
              <AddIcon />
            </span>
          </header>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='notifications'>
              {provided => (
                <ul
                  className='notifications-list'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {notifications.map((item, index) => {
                    return (
                      <li
                        key={item.id}
                        className='notifications-list_item-wrapper'
                      >
                        <Draggable
                          draggableId={item.id.toString()}
                          index={index}
                        >
                          {provided => (
                            <div
                              className='notifications-list_item-inner'
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className='notifications-list_item notifications-list_item--id'>
                                <div className='notifications-list_item-title'>
                                  Id
                                </div>
                                <div className='notifications-list_item-body'>
                                  {item.id}
                                </div>
                              </div>
                              <div className='notifications-list_item notifications-list_item--status'>
                                <div className='notifications-list_item-title'>
                                  Status
                                </div>
                                <div className='notifications-list_item-body'>
                                  {NOTIFICATION_PUSH_STATUS[item.status]}
                                </div>
                                <div className='notifications-list_item-body'>
                                  {moment(item.updated).format('MM/DD/YYYY hh:mm A')}
                                  {/*{moment(item.scheduledTime).format('MM/DD/YYYY hh:mm A')}*/}
                                  {/* change item.update to schedule timezone*/}
                                </div>
                              </div>
                              <div className='notifications-list_item'>
                                <div className='notifications-list_item-title'>
                                {item.title && 'Title/'}
                                  {item.body && 'Body/'}
                                  {item.url && 'Url'}
                                </div>
                                <div className='notifications-list_item-body-wrapper'>
                                  {item.title && (
                                    <div className='notifications-list_item-body-inner'>
                                      {item.title}
                                    </div>
                                  )}

                                  {item.body && (
                                    <div className='notifications-list_item-body-inner'>
                                      {item.body}
                                    </div>
                                  )}

                                  {item.url && (
                                    <div className='notifications-list_item-body-inner'>
                                      {item.url}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='notifications-list_item notifications-list_item--buttons'>
                                <div className='notifications-list_item-title'>
                                  Actions
                                </div>
                                <div className='notifications-list_item-body'>
                                  <button
                                      className='table-list_item-btn'
                                      disabled={isDisabledBtn(item.id)}
                                      onClick={async () => {
                                        try {
                                          loadingStart(
                                              NotificationsLoadingTypes.GET_ONE,
                                          )

                                          const {data: notification} =
                                              await NotificationService.getNotificationById(
                                                  item.id,
                                              )

                                          setNotificationsData({
                                            ...notification,
                                          })
                                          setModalActive(true)
                                        } catch (e) {
                                        } finally {
                                          loadingEnd(
                                              NotificationsLoadingTypes.GET_ONE,
                                          )
                                        }
                                      }}
                                      title="Edit"
                                  >
                                    <EditIcon/>
                                  </button>
                                  <button
                                      className='table-list_item-btn'
                                      onClick={async () => {
                                        setDeleteCandidateNotification(item)
                                        setConfirmDeleteNotificationModalActive(
                                            true,
                                        )
                                      }}
                                      title="Delete"
                                  >
                                    <DeleteIcon/>
                                  </button>
                                  <button
                                      className='table-list_item-btn'
                                      disabled={isDisabledBtn(item.id)}
                                      onClick={async () => {
                                        try {
                                          loadingStart(
                                              NotificationsLoadingTypes.PUSH,
                                          )
                                          await NotificationService.sendNotification(
                                              item.id,
                                          )
                                        } catch {
                                        } finally {
                                          loadingEnd(
                                              NotificationsLoadingTypes.PUSH,
                                          )
                                        }

                                        setIsPushNotifications(oldState => [
                                          ...oldState,
                                          item.id,
                                        ])
                                      }}
                                      title="Bell"
                                  >
                                    <BellIcon/>
                                  </button>
                                  <button
                                      className='table-list_item-btn'
                                      onClick={async () => {
                                        setNotificationsData({
                                          ...item,
                                          id: item.id
                                        });
                                        if (item.updated) {
                                          const date = moment(item.updated);
                                          setSelectedDate(date.format('YYYY-MM-DD'));
                                          setSelectedTime(date.format('HH:mm'));
                                        } else {
                                          setSelectedDate('');
                                          setSelectedTime('');
                                        }
                                        setDeleteScheduleNotification(item)
                                        setOpenScheduledModal(true);
                                      }}
                                      title="Scheduled"
                                  >
                                    <ScheduleIcon/>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </li>
                    )
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <Pagination
              perPage={10}
              totalCount={totalCount}
              reload={reload}
          ></Pagination>

          <Modal
              active={modalActive}
              setActive={setModalActive}
              title={
                renderNotificationModalForm()
                    ? 'Create notification'
                    : 'Update notification'
              }
          >
            <form
                className='notifications-form modal-form'
                onSubmit={formSubmitHandle}
            >
              <label>
                <div>Title</div>
                <input
                    type='text'
                    value={title}
                    onChange={e => {
                      setNotificationsData(oldS => ({
                        ...oldS,
                        title: e.target.value,
                      }))
                    }}
                />
              </label>
              <label>
              <div>Body</div>
                <textarea
                  type='text'
                  value={body}
                  onChange={e => {
                    setNotificationsData(oldS => ({
                      ...oldS,
                      body: e.target.value,
                    }))
                  }}
                />
              </label>
              <label>
                <div>Url</div>
                <input
                  type='text'
                  value={url}
                  onChange={e => {
                    setNotificationsData(oldS => ({
                      ...oldS,
                      url: e.target.value,
                    }))
                  }}
                />
              </label>
              <button className='notifications-form_btn admin-btn'>Save</button>
            </form>
          </Modal>
          <Modal
            confirm
            active={confirmDeleteNotificationModalActive}
            setActive={setConfirmDeleteNotificationModalActive}
            title={`Delete Notification`}
            footer={
              <div className='notifications_delete-pass-footer'>
                <div className='modal-footer-buttons'>
                  <button
                    className='modal-footer-buttons_btn admin-btn'
                    onClick={() => {
                      deleteNotification(deleteCandidateNotification.id)
                      setConfirmDeleteNotificationModalActive(false)
                    }}
                  >
                    Ok
                  </button>
                  <button
                    className='modal-footer-buttons_btn admin-btn'
                    onClick={() => {
                      setConfirmDeleteNotificationModalActive(false)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            }
          >
            <div className='notifications_delete-notification delete-modal-description'>
              Do you really want to delete Notification
            </div>
          </Modal>
          <Modal
              confirm
              active={openScheduledModal}
              setActive={setOpenScheduledModal}
              title={`Schedule Notification`}
              footer={
                <div className='notifications_delete-pass-footer'>
                  <div className='modal-footer-buttons'>
                    <button
                        className='modal-footer-buttons_btn admin-btn'
                        onClick={handleScheduledSave}
                        disabled={isSaveDisabled}
                        style={{cursor: isSaveDisabled ? 'not-allowed' : 'pointer'}}
                    >
                      Save
                    </button>
                    {scheduleTimeZOne ? <button
                        className='modal-footer-buttons_btn admin-btn'
                        onClick={() => {
                          cancelSchedule(deleteScheduleNotification.id)
                          setOpenScheduledModal(false)
                        }}
                    >
                      Delete
                    </button> : <></>}

                    <button
                        className='modal-footer-buttons_btn admin-btn'
                        onClick={() => {
                          setOpenScheduledModal(false)
                        }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              }
          >
            <div className='notifications_delete-notification delete-modal-description'>
              <input
                  type="date"
                  id="date-picker"
                  placeholder="Select Date"
                  style={{marginRight: 10}}
                  value={scheduleTimeZOne? selectedDate : null}
                  onChange={handleDateChange}
              />
              <input
                  type="time"
                  id="time-picker"
                  placeholder="Select Time"
                  value={scheduleTimeZOne ? selectedTime : null}
                  onChange={handleTimeChange}
              />
            </div>
          </Modal>
        </div>
      ) : (
          !notFilter && (
              <div className='notifications_not'>
                <h1 className='notifications_not-description'>
              Please select Pass
            </h1>
          </div>
        )
      )}
    </>
  )
}

export default Notifications
