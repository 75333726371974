import { $private } from '../http'

export default class NotificationService {
  static async getNotificationListByPassId({ filter, limit, offset }) {
    return $private.post(`/Notification/list?limit=${limit}&offset=${offset}`, {
      ...filter,
    })
  }
  static async getNotificationById(id) {
    return $private.get(`/Notification/${id}`)
  }
  static async updateOrder(body) {
    return $private.post(`/Notification/updateOrder`, { ...body })
  }
  static async createNotification(data) {
    return $private.post(`/Notification`, data)
  }
  static async updateNotificationById(data) {
    return $private.put(`/Notification`, data)
  }
  static async deleteNotificationById(id) {
    return $private.delete(`/Notification/${id}`)
  }
  static async cancelScheduleById(id) {
    return $private.patch(`/Notification/${id}/cancel`)
  }
  static async sendNotification(id) {
    return $private.post(`/Notification/Send`, { id })
  }
  static async sendScheduled(id, date, time) {
    const data = {
      id,
      date,
      time
    };
    return $private.post(`/Notification/sendScheduled`, data)
  }
  static async getNotificationsCount(filter) {
    return $private.post(`/Notification/count`, filter)
  }
}
